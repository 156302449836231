import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import GatsbyImgae from '../GatsbyImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  SpeakerdeckItem,
  SpeakerdeckProgress
} from './index.style'

const SpeakerdeckSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  dateDescription,
  row,
  col,
  avator,
  viewDescription
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allSpeakerdeckYaml {
        edges {
          node {
            id
            title
            view
            image
            href
            date
          }
        }
      }       
    }
  `);

  const formatter = new Intl.NumberFormat('ja-JP')
  const totalView = Data.allSpeakerdeckYaml.edges.map(({ node }) => node.view).reduce((p, x) => p + x, 0)

  return (
    <Box {...sectionWrapper} as="section" id="speakers_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Speakers" />
          <Text {...secDescription} content={Data.allSpeakerdeckYaml.edges.length + ' slides : Total ' + formatter.format(totalView) + ' views.'} />
          <Text {...secDescription} content={'Page views are aggregated for speakerdeck and slideshare.'} />
        </Box>

        <Box {...row}>
          {Data.allSpeakerdeckYaml.edges.map(({ node }, index) => (
            <Box {...col} key={`speakerdeck-item-${index}`}>
              <OutboundLink href={node.href} target="_blank" rel="noopener noreferrer">
                <SpeakerdeckItem>
                  <GatsbyImgae filename={node.id + '.jpg'} alt={`preview-${index + 1}`} style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} />
                  <SpeakerdeckProgress>
                    <Image {...avator} src="//secure.gravatar.com/avatar/acbf3391de0494432a92221ffe89f34e?s=48" alt={node.title} />
                    <Text content={node.date} {...dateDescription} />

                    <FontAwesomeIcon icon={["fas", "fa-eye"]} />
                    <Text content={formatter.format(node.view)} {...viewDescription} />
                  </SpeakerdeckProgress>
                </SpeakerdeckItem>
              </OutboundLink>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

SpeakerdeckSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  speakerdeckDescription: PropTypes.object,
  speakerdeckSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

SpeakerdeckSection.defaultProps = {
  sectionWrapper: {
    pt: '60px',
    pb: '60px',
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: '40px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'left',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1 / 3],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  speakerdeckDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  dateDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'left',
    maxWidth: '100%',
    ml: '16px',
    mr: 'auto'
  },
  viewDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'right',
    maxWidth: '100%',
    ml: 'auto'
  },
  speakerdeckSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
  avator: {
    borderRadius: '50%',   
  }
};

export default SpeakerdeckSection;
