import styled from 'styled-components';

export const SpeakerdeckItem = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0.521px 2.954px 50px 0px rgba(101, 106, 160, 0.1);
`;

export const SpeakerdeckDetails = styled.div`
  border-top: 1px solid #f7f7f7;
  padding: 24px 24px 48px 24px;
  display: flex;
  align-items: center;
  @media (max-width: 1199px) {
    padding: 70px 45px 40px 45px;
  }
  @media (max-width: 990px) {
    padding: 60px 35px 30px 35px;
  }
  @media (max-width: 575px) {
    padding: 35px 25px 20px 25px;
  }
`;


export const SpeakerdeckAbout = styled.div``;

export const SpeakerdeckProgress = styled.div`
  padding: 24px 24px 24px 24px;
  border-top: 1px solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1199px) {
    padding: 20px 30px 28px 30px;
  }
  @media (max-width: 575px) {
    padding: 15px 20px 18px 20px;
  }
`;


export const ProgressBar = styled.div`
  flex: 0 0 58%;
  max-width: 58%;
  @media (max-width: 1199px) {
    flex: 0 0 70%;
    max-width: 70%;
  }

  > svg {
    vertical-align: middle;
  }
`;
